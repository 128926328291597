<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <div class="col-12">Marketplace Faturaları</div>
          </div>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol col="4">
              <CInput
                description="Başlangıç Tarihi"
                type="date"
                horizontal
                v-model="startDate"
              />
            </CCol>
            <CCol col="4">
              <CInput
                description="Bitiş Tarihi"
                type="date"
                horizontal
                v-model="endDate"
              />
            </CCol>
            <CCol col="4">
              <CRow>
                <CCol>
                  <CButton color="success" @click="marketPlaceInvoicesInvoices(startDate, endDate,status,orderNumber, providerId)">Listele</CButton>
                </CCol>
              </CRow>
              <CRow>&nbsp;</CRow>
              <CRow>
                <CCol>
                  <CButton color="dark" @click="openModalEvent('upload', 'marketPlaceForm', {}, 'Excel Dosyası Yükle')">Excel Yükle</CButton>&nbsp;&nbsp;
                  <CButton color="dark" @click="excelExportByMarketplaceInvoices">PDF Kaydet ve İndir</CButton>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <CRow>
            <CCol col="4">
              <CInput
                description="Sales/Return"
                type="text"
                horizontal
                v-model="status"
              />
            </CCol>
            <CCol col="4">
              <CInput
                description="Order Number"
                placeholder="000000"
                type="text"
                horizontal
                v-model="orderNumber"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol  col="4">
              <CSelect
                description="Providers"
                placeholder="Select Provider"
                horizontal
                :options="marketPlaceProviders"
                :value.sync="providerId"
              >
              </CSelect>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <CCard>
        <CCardBody>
          <CRow>
            <CCol col="12">
              <CDataTable
                :items="marketPlaceInvoices"
                :fields="fields"
                :loading="loading"
                :items-per-page="10"
                pagination
                hover
                clickable-rows
                v-on:refresh="marketPlaceInvoicesInvoices(startDate, endDate,status,orderNumber, providerId)"
              >
                <template #createTime="{item}">
                  <td> {{ item.createTime && dateFormat(item.createTime) }}</td>
                </template>
                <template #invoiceCreateTime="{item}">
                  <td> {{ item.invoiceCreateTime && dateFormat(item.invoiceCreateTime) }}</td>
                </template>
              </CDataTable>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
    <ConfirmModal
        v-if="form == 'confirm'"
        :show="openModal"
        :title="modalTitle"
        :openModalEvent="openModalEvent"
        :confirm="openModalEvent"
        :desc="modalDesc"
        :noFooter="true"
        size="lg"
    />
    <FormModal
      v-else
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :operationEvent="operationEvent"
      :actionType="actionType"
      :module="module"
      :form="form"
      :data="modalProps"
    />
  </CRow>
</template>
<script>


import moment from "moment";
import FormModal from '../components/formModal'
import ConfirmModal from "../components/confirmModal";

export default {
  name: 'MarketPlaceInvoices',
  data() {
    return {
      modalTitle: '',
      actionType: '',
      module: 'orders',
      form: 'marketPlaceForm',
      modalProps: {},
      modalDesc: '',
      openModal: false,
      fields: [
        {key: 'createTime', label: 'Create_Time', _style: 'font-size:12px'},
        {key: 'invoiceCreateTime', label: 'INV_Created_Date', _style: 'font-size:12px'},
        {key: 'currency', label: 'Currency', _style: 'font-size:12px'},
        {key: 'invoiceStatus', label: 'Sales/Return', _style: 'font-size:12px'},
        {key: 'orderNumber', label: 'Order_Number', _style: 'font-size:12px'},
        {key: 'sku', label: 'SKU', _style: 'font-size:12px'},
        {key: 'productTitle', label: 'Product_Title', _style: 'font-size:12px'},
        {key: 'ean', label: 'EAN', _style: 'font-size:12px'},
        {key: 'invoiceItemUnit', label: 'İtem_Unit', _style: 'font-size:12px'},
        {key: 'invoiceItemVatRate', label: 'İtem_Vat_Rate', _style: 'font-size:12px'},
        {key: 'invoiceItemVatExclusivePrice', label: 'İtem_Vat_Ex_Price', _style: 'font-size:12px'},
        {key: 'invoiceItemVatAmount', label: 'İtem_Vat_Amount', _style: 'font-size:12px'},
        {key: 'invoiceTotalPrice', label: 'Invoice_Total_Price', _style: 'font-size:12px'},
        {key: 'customerFirstName', label: 'Customer_First_Name', _style: 'font-size:12px'},
        {key: 'customerLastName', label: 'Customer_Last_Name', _style: 'font-size:12px'},
        {key: 'shippingAddress1', label: 'Ship_To_Address1', _style: 'font-size:12px'},
        {key: 'shippingAddress2', label: 'Ship_To_Address1', _style: 'font-size:12px'},
        {key: 'city', label: 'City', _style: 'font-size:12px'},
        {key: 'countryChannel', label: 'Channel', _style: 'font-size:12px'},
      ],
      startDate: '',
      endDate: '',
      status: '',
      providerId: '',
      provider: '',
      freeText:'',
      orderNumber: '',
      file: ''
    }
  },
  computed: {
    marketPlaceInvoices() {
      return this.$store.getters.marketPlaceInvoices;
    },
    loading() {
      return this.$store.getters.orderLoading;
    },
    marketPlaceProviders(){
      let data = []
      this.$store.getters.marketPlaceProviders.map(provider => data.push({value: provider.id, label: provider.providerName }))
      return data
    }
  },
  components: {ConfirmModal, FormModal},
  methods: {
    dateFormat(date){
      return moment(date).format("DD.MM.YYYY HH:mm:ss")
    },

    async openModalEvent(actionType, form, data, title, desc){
      data && data.file == null
      this.openModal = !this.openModal
      this.actionType = actionType
      this.form = form
      this.modalProps = data
      this.modalTitle = title
      this.modalDesc = desc
    },

    async marketPlaceInvoicesInvoices(startDate, endDate, status, order, providerId) {
      let params = {
        "status": status,
        "order": order,
        "providerId": providerId,
        "startDate":  moment(startDate).format('YYYY-MM-DDTHH:mm:ss:SSZ'),
        "endDate": moment(endDate).format('YYYY-MM-DDTHH:mm:ss:SSZ'),
      }
      await this.$store.dispatch('order_marketPlaceInvoices', params)
    },
    async operationEvent(item, action) {
      let formData = new FormData();
      formData.append("file", item.file);
      formData.append("provider", item.provider);
      formData.append("freeText", item.freeText);
      await this.$store.dispatch('order_importMarketPlaceInvoicesExcel', formData)
      if(this.$store.getters.orderStatus.success){
        this.openModalEvent()
      }
    },
    async excelExportByMarketplaceInvoices() {
      await this.$store.dispatch('order_marketPlaceInvoicesExcel')
      if(this.$store.getters.orderStatus.success){
        this.openModalEvent('download', 'confirm', {}, 'PDF Kaydet ve İndir', this.$store.getters.orderStatus.message)
      }
    },
  },
  created() {
    this.status = 'SALES';
    var today = new Date();
    this.endDate = moment(today).format('YYYY-MM-DD');
    this.startDate = moment(today).format('YYYY-MM-DD');
    this.$store.dispatch('order_marketPlaceProviders')
  }
}
</script>
